import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Flex,
  FormControl,
  Input,
  Select,
  HStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CloseIcon } from "@chakra-ui/icons";
import { CreateProductRequest } from "generated-client/model/create-product-request";
import { createProduct } from "services/product";

interface ProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  supplierId: string;
  updateRefresh: (value: boolean) => void;
}

const ProductModal: React.FC<ProductModalProps> = ({
  onClose,
  isOpen,
  supplierId,
  updateRefresh,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [productPayloads, setProductPayloads] = useState<
    Omit<CreateProductRequest, "supplierId">[]
  >([
    {
      name: "",
      stockAvailability: "IN_STOCK",
    },
  ]);
  const [error, setError] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const Options = ["IN_STOCK", "OUT_OF_STOCK"];

  const handleValueChange = (
    index: number,
    key: keyof CreateProductRequest,
    value: string
  ) => {
    const updatedPayloads = [...productPayloads];
    (updatedPayloads[index] as any)[key] = value;
    setProductPayloads(updatedPayloads);
  };

  const handleAddField = () => {
    setProductPayloads([
      ...productPayloads,
      {
        name: "",
        stockAvailability: "IN_STOCK",
      },
    ]);
  };

  const handleRemoveField = (indexToRemove: number) => {
    if (productPayloads.length > 1) {
      setProductPayloads((prevPayloads) =>
        prevPayloads.filter((_, index) => index !== indexToRemove)
      );
    }
  };

  const handleSubmit = async () => {
    setError("");
    const invalidPayload = productPayloads.some(
      (payload) => !payload.name.trim()
    );
    if (invalidPayload) {
      setError("Name can not be empty");
      return;
    }

    setIsSubmitLoading(true);

    const accessToken = await getAccessTokenSilently();

    try {
      await Promise.all(
        productPayloads.map((payload) =>
          createProduct(accessToken, { ...payload, supplierId })
        )
      );
      onClose();
      setProductPayloads([
        {
          name: "",
          stockAvailability: "IN_STOCK",
        },
      ]);
      updateRefresh(true);
    } catch (error) {
      console.error("Error creating product:", error);
      setError(
        "An error occurred while creating the product. Please try again."
      );
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="space-between" alignItems="center">
            <Text>Add Product</Text>
            <CloseIcon onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <FormControl>
            {productPayloads.map((payload, index) => (
              <FormControl key={index}>
                <Flex gap="2" alignItems="center" mb={8}>
                  <Input
                    placeholder="Product Name"
                    value={payload.name}
                    onChange={(e) =>
                      handleValueChange(index, "name", e.target.value)
                    }
                    width={"55%"}
                  />
                  <Select
                    value={payload.stockAvailability}
                    onChange={(e) =>
                      handleValueChange(
                        index,
                        "stockAvailability",
                        e.target.value
                      )
                    }
                    width={"25%"}
                  >
                    {Options.map((val, index) => (
                      <option key={index} value={val}>
                        {val.replace(/_/g, " ")}
                      </option>
                    ))}
                  </Select>
                  <HStack w={"5%"}>
                    {index === productPayloads.length - 1 && (
                      <Button
                        onClick={handleAddField}
                        colorScheme="blackAlpha"
                        h={4}
                      >
                        +
                      </Button>
                    )}
                  </HStack>

                  <HStack w={"5%"}>
                    {index > 0 && (
                      <Button
                        onClick={() => handleRemoveField(index)}
                        colorScheme="blackAlpha"
                        ml={4}
                        h={4}
                      >
                        X
                      </Button>
                    )}
                  </HStack>
                </Flex>
              </FormControl>
            ))}
          </FormControl>

          <Flex justifyContent="flex-start" mt={4}>
            {error && (
              <Text color="red.500" mt={2}>
                {error}
              </Text>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="center">
            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              isLoading={isSubmitLoading}
              disabled={isSubmitLoading}
            >
              Add
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProductModal;
