import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import Card from "../../../../components/card/Card";

import { ProductDto } from "generated-client/model/product-dto";

interface TableComponentProps {
  products: ProductDto[];
}

export default function TableComponent({ products }: TableComponentProps) {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        w={{ base: "100%", md: "100%" }}
        mb={{ base: "0px", xl: "20px" }}
        minH="365px"
        pe="20px"
      >
        <Table variant="simple" size="md" fontSize="0.9em">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Supplier Name</Th>
              <Th>Stock Availability</Th>
            </Tr>
          </Thead>
          <Tbody>
            {products?.map((product) => (
              <Tr key={product.id}>
                <Td>
                  <Text>{product.name}</Text>
                </Td>
                <Td>
                  <Text>{product?.supplier?.name}</Text>
                </Td>
                <Td>
                  <Text>{product.stockAvailability.replace(/_/g, " ")}</Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
    </Box>
  );
}
