import { Box, Text } from "@chakra-ui/react";
import { ProductDto } from "generated-client/model/product-dto";

interface CardLayoutProps {
  products: ProductDto[];
}

export default function CardLayout({ products }: CardLayoutProps) {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {products?.map((product) => (
        <Box
          key={product.id}
          bg="white"
          p={5}
          shadow="md"
          borderWidth="1px"
          mb={4}
        >
          <Text fontSize="xl">{product.name}</Text>

          {/* Info section */}
          <Box mb={4} fontSize="0.9em">
            <Text>Supplier Name: {product?.supplier?.name}</Text>
            <Text>
              Stock Availability: {product.stockAvailability.replace(/_/g, " ")}
            </Text>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
