import { useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  downloadPurchaseOrder,
  getPaymentRequests,
} from "../../../services/payment-request";
import { PaymentRequestDto } from "../../../generated-client/model/payment-request-dto";
import TableComponent from "./components/Table";
import CardLayout from "./components/CardLayout";
import { downloadFile, uploadFile } from "../../../services/file";
import { attachProofOfUserPayment } from "../../../services/payment-request";
import SuccessModal from "./components/SuccessModal";
import { SupplierDto } from "../../../generated-client/model/supplier-dto";
import { getSuppliers } from "../../../services/supplier";
import { CompanyDto } from "../../../generated-client/model/company-dto";
import CountryEnum = CompanyDto.CountryEnum;
import { getCompany } from "../../../services/company";

export default function MyPaymentRequestsPage() {
  const [paymentRequests, setPaymentRequests] = useState<PaymentRequestDto[]>(
    []
  );
  const [suppliers, setSuppliers] = useState<SupplierDto[]>([]);
  const [loadingStates, setLoadingStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [userCountry, setUserCountry] = useState<CountryEnum>();
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const isTableLayout = useBreakpointValue({ base: false, md: true });
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  async function fetchData() {
    try {
      const accessToken = await getAccessTokenSilently();
      const data = await getPaymentRequests(accessToken);
      const suppliers = await getSuppliers(accessToken);
      const company = await getCompany(accessToken);

      setPaymentRequests(data.paymentRequests);
      setSuppliers(suppliers.suppliers);
      setUserCountry(company.company.country);
    } catch (error) {
      console.error("Error fetching payment requests:", error);
    }
  }

  const setRowLoading = (id: string, isLoading: boolean) => {
    setLoadingStates((prev) => ({ ...prev, [id]: isLoading }));
  };

  const handlePurchaseOrderDownload = async (request: PaymentRequestDto) => {
    setRowLoading(request.id, true);
    const accessToken = await getAccessTokenSilently();
    await downloadPurchaseOrder(request.id, accessToken);
    setRowLoading(request.id, false);
  };

  const downloadFileForRequest = async (id: string) => {
    const accessToken = await getAccessTokenSilently();
    downloadFile(id, accessToken);
  };

  async function sendFile(file: File, paymentRequestId: string) {
    const accessToken = await getAccessTokenSilently();
    try {
      const fileUploaded = await uploadFile(
        accessToken,
        {
          paymentRequestId: paymentRequestId,
          type: "PROOF_OF_USER_PAYMENT",
        },
        file
      );

      await attachProofOfUserPayment(accessToken, {
        id: paymentRequestId,
        fileId: fileUploaded.id,
      });

      setIsSuccessModalOpen(true);
    } catch (error) {
      console.error("Error fetching payment requests:", error);
    }
  }

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isAuthenticated) {
      fetchData();
    }
  }, [isLoading, isAuthenticated]);

  return (
    <>
      {isTableLayout ? (
        <TableComponent
          paymentRequests={paymentRequests}
          loadingStates={loadingStates}
          suppliers={suppliers}
          sendFile={sendFile}
          downloadFile={downloadFileForRequest}
          setRowLoading={setRowLoading}
          handlePurchaseOrderDownload={handlePurchaseOrderDownload}
          country={userCountry}
        />
      ) : (
        <CardLayout
          sendFile={sendFile}
          suppliers={suppliers}
          downloadFile={downloadFileForRequest}
          setRowLoading={setRowLoading}
          paymentRequests={paymentRequests}
          loadingStates={loadingStates}
          handlePurchaseOrderDownload={handlePurchaseOrderDownload}
          country={userCountry}
        />
      )}
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => {
          setIsSuccessModalOpen(false);
          window.location.reload();
        }}
      />
    </>
  );
}
