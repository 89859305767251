import {
  Button,
  Text,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useBreakpointValue,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { Fragment, useEffect, useMemo, useState } from "react";
import { getSupplierDetailAdmin, updateSupplier } from "services/supplier";
import { useAuth0 } from "@auth0/auth0-react";
import { CreateSupplierRequest } from "generated-client/model/create-supplier-request";
import ProductModal from "./ProductModal";
import {
  deleteProduct,
  getAllProductsBySupplierId,
  updateProduct,
} from "services/product";
import { UpdateProductRequest } from "generated-client/model/update-product-request";
import Pagination from "views/admin/sharedComponents/pagination";
import { ProductDto } from "generated-client/model/product-dto";

interface EditSupplierModalProps {
  isOpen: boolean;
  onClose: () => void;
  supplierId: string;
}

const EditSupplierModal: React.FC<EditSupplierModalProps> = ({
  isOpen,
  onClose,
  supplierId,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [supplierPayload, setSupplierPayload] = useState<CreateSupplierRequest>(
    {
      name: "",
      friendlyName: "",
      bankName: "",
      accountNumber: "",
      mobilePayAccountName: "",
      mobilePayNumber: "",
      whatsappContact: "",
      country: "",
      address: "",
      companyRegistrationDate: "",
      email: "",
      managingDirector: "",
      mobileNumber: "",
    }
  );
  const [error, setError] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isNewProductModalOpen, setIsNewProductModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [supplierProducts, setSupplierProducts] = useState<ProductDto[]>([]);

  const isTableLayout = useBreakpointValue({ base: false, lg: true });
  const countries = ["Rwanda", "Kenya"];
  const Options = ["IN_STOCK", "OUT_OF_STOCK"];

  const pageSize = 20;

  const otherFields = [
    { key: "name", label: "Supplier Name" },
    { key: "friendlyName", label: "Listing Name" },
    { key: "country", label: "Country" },
    { key: "address", label: "Address" },
    { key: "companyRegistrationDate", label: "Company Registration Date" },
    { key: "managingDirector", label: "Managing Director" },
    { key: "email", label: "Email" },
    { key: "whatsappContact", label: "Mobile Contact" },
  ];

  const sections = [
    {
      label: "Bank account",
      fields: [
        { key: "bankName", label: "Bank Name" },
        { key: "accountNumber", label: "Account Number" },
      ],
    },
    {
      label: "Mobile Money",
      fields: [
        { key: "mobilePayAccountName", label: "Mobile Pay Account Name" },
        { key: "mobilePayNumber", label: "Mobile Pay Number" },
      ],
    },
  ];

  const handleValueChange = (
    key: keyof CreateSupplierRequest,
    value: string
  ) => {
    setSupplierPayload((prevPayload) => ({
      ...prevPayload,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {
    setError("");
    if (!supplierPayload.name) {
      setError("Name can not be empty");
      return;
    }

    if (!supplierPayload.friendlyName) {
      setError("Friendly name can not be empty");
      return;
    }
    if (!supplierPayload.country) {
      setError("Country can not be empty");
      return;
    }

    if (
      supplierPayload.email &&
      !supplierPayload.email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setError("Invalid email");
      return;
    }

    setIsSubmitLoading(true);

    const accessToken = await getAccessTokenSilently();

    await updateSupplier(accessToken, {
      ...supplierPayload,
      // @ts-ignore
      companyRegistrationDate: new Date(
        supplierPayload.companyRegistrationDate
      ),
      id: supplierId,
    });
    setIsSubmitLoading(false);
    onClose();
  };

  const fetchSupplierProducts = async ({
    skip = 0,
    take = 20,
  }: {
    skip?: number;
    take?: number;
  }) => {
    const accessToken = await getAccessTokenSilently();
    const { products } = await getAllProductsBySupplierId(accessToken, {
      supplierId,
      skip,
      take,
    });
    setSupplierProducts(products);
  };

  const fetchSupplierDetail = async () => {
    const accessToken = await getAccessTokenSilently();
    const { supplier } = await getSupplierDetailAdmin(accessToken, supplierId);
    const { id, createdAt, updatedAt, ...payloadWithoutIdAndDates } = supplier;
    setSupplierPayload(payloadWithoutIdAndDates as CreateSupplierRequest);
  };

  const updateProductItem = async (payload: UpdateProductRequest) => {
    const accessToken = await getAccessTokenSilently();
    const { productId } = await updateProduct(accessToken, payload);
    fetchSupplierProducts({});
    return productId;
  };

  const removeProductItem = async (productId: string) => {
    const accessToken = await getAccessTokenSilently();
    await deleteProduct(accessToken, productId);
    fetchSupplierProducts({});
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const filteredProducts = useMemo(() => supplierProducts, [supplierProducts]);

  useEffect(() => {
    if (isOpen) {
      fetchSupplierDetail();
      fetchSupplierProducts({});
      setRefresh(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierId, refresh, isOpen]);

  useEffect(() => {
    if (isOpen) {
      fetchSupplierProducts({
        skip: (currentPage - 1) * pageSize,
        take: pageSize,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, refresh, isOpen]);

  return (
    <Fragment>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
        <ModalContent>
          <ModalHeader>
            <Flex alignItems="center" justifyContent="space-between">
              <Text>Supplier Profile</Text>
              <Button
                colorScheme="blue"
                onClick={() => setIsNewProductModalOpen(true)}
              >
                Add Product
              </Button>
            </Flex>
          </ModalHeader>
          <ModalBody overflowY="scroll" maxH="calc(100vh - 200px)">
            <Flex gap="8" flexDirection={isTableLayout ? "row" : "column"}>
              <VStack>
                <FormControl>
                  {otherFields.map((field) =>
                    field.key === "country" ? (
                      <Fragment key={field.label}>
                        <FormLabel>{field.label}</FormLabel>
                        <Select
                          placeholder="Select country"
                          value={
                            supplierPayload[
                              field.key as keyof CreateSupplierRequest
                            ]
                          }
                          onChange={(e) =>
                            handleValueChange(
                              field.key as keyof CreateSupplierRequest,
                              e.target.value
                            )
                          }
                          mb={4}
                          w={!isTableLayout ? "100%" : 300}
                        >
                          {countries.map((country, index) => (
                            <option key={index} value={country}>
                              {country}
                            </option>
                          ))}
                        </Select>
                      </Fragment>
                    ) : (
                      <FormControl key={field.key}>
                        <FormLabel>{field.label}</FormLabel>
                        <Input
                          w={!isTableLayout ? "100%" : 300}
                          placeholder={field.label}
                          value={
                            field.key === "companyRegistrationDate" &&
                            supplierPayload.companyRegistrationDate
                              ? new Date(
                                  supplierPayload.companyRegistrationDate
                                )
                                  .toISOString()
                                  .split("T")[0]
                              : supplierPayload[
                                  field.key as keyof CreateSupplierRequest
                                ]
                          }
                          onChange={(e) =>
                            handleValueChange(
                              field.key as keyof CreateSupplierRequest,
                              e.target.value
                            )
                          }
                          mb={4}
                          type={
                            field.key === "email"
                              ? "email"
                              : field.key === "companyRegistrationDate"
                              ? "date"
                              : field.key === "mobileNumber" ||
                                field.key === "mobilePayNumber"
                              ? "tel"
                              : "text"
                          }
                        />
                      </FormControl>
                    )
                  )}

                  {sections.map((section, index) => (
                    <FormControl key={index}>
                      <FormLabel fontWeight="bold">{section.label}</FormLabel>
                      {section.fields.map((field) => (
                        <FormControl key={field.key}>
                          <Input
                            placeholder={field.label}
                            value={
                              supplierPayload[
                                field.key as keyof CreateSupplierRequest
                              ]
                            }
                            onChange={(e) =>
                              handleValueChange(
                                field.key as keyof CreateSupplierRequest,
                                e.target.value
                              )
                            }
                            mb={4}
                            w={!isTableLayout ? "100%" : 300}
                          />
                        </FormControl>
                      ))}
                    </FormControl>
                  ))}
                </FormControl>
              </VStack>

              <VStack>
                <Table variant="simple" size="md" fontSize="0.9em">
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Availability</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredProducts?.map((product) => (
                      <Tr key={product.id}>
                        <Td>
                          <Text>{product.name}</Text>
                        </Td>
                        <Td>
                          <Select
                            value={product.stockAvailability}
                            onChange={(e) =>
                              updateProductItem({
                                ...product,
                                stockAvailability: e.target
                                  .value as UpdateProductRequest.StockAvailabilityEnum,
                              })
                            }
                          >
                            {Options.map((val, index) => (
                              <option key={index} value={val}>
                                {val.replace(/_/g, " ")}
                              </option>
                            ))}
                          </Select>
                        </Td>
                        <Td>
                          <Button
                            colorScheme="red"
                            onClick={() => removeProductItem(product.id)}
                            height={6}
                          >
                            X
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>

                <Pagination
                  currentPage={currentPage}
                  pageSize={pageSize}
                  elementsCount={filteredProducts.length}
                  onPageChange={handlePageChange}
                />
              </VStack>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-start" mt={4}>
              {error && (
                <Text color="red.500" mt={2}>
                  {error}
                </Text>
              )}
            </Flex>
            <Flex width="100%" justifyContent="center" gap={4}>
              <Button
                colorScheme="blue"
                onClick={handleSubmit}
                isLoading={isSubmitLoading}
                disabled={isSubmitLoading}
              >
                Update
              </Button>
              <Button colorScheme="blackAlpha" onClick={onClose}>
                Close
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ProductModal
        isOpen={isNewProductModalOpen}
        onClose={() => {
          setIsNewProductModalOpen(false);
        }}
        supplierId={supplierId}
        updateRefresh={setRefresh}
      />
    </Fragment>
  );
};

export default EditSupplierModal;
