import {
  Button,
  SimpleGrid,
  Text,
  Tooltip,
  useColorModeValue,
  useBreakpointValue,
  Box,
  Icon,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import Information from "./Information";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../../../../lib/utilities";
import { CompanyDto } from "../../../../generated-client/model/company-dto";
import CountryEnum = CompanyDto.CountryEnum;
import { MdLanguage, MdOutlineShoppingCart } from "react-icons/md";

interface GeneralInformationProps {
  companyName: string;
  companyAddress: string;
  creditLimit: number;
  amountDue: number;
  exchangeRate: string;
  dueDate: string;
  companyCountry: CountryEnum;
  [x: string]: any;
  isDue: boolean;
}

export default function GeneralInformation(props: GeneralInformationProps) {
  const {
    companyName,
    companyAddress,
    creditLimit,
    exchangeRate,
    amountDue,
    dueDate,
    companyCountry,
    isDue,
    ...rest
  } = props;
  const navigate = useNavigate();

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  const isCreditFullyUsed = creditLimit <= 0;

  const columns = useBreakpointValue({ base: 1, md: 2, lg: 4 }); // Responsive columns based on the screen size
  const columnsOptions = useBreakpointValue({ base: 1, md: 2, lg: 3 }); // Responsive columns based on the screen size

  const FlexPayButton = ({
    onClick,
    isDisabled,
  }: {
    onClick: () => void;
    isDisabled: boolean;
  }) => (
    <Tooltip hasArrow={isDisabled}>
      <Button
        borderWidth={1}
        backgroundColor="#E7F2FF"
        p="24px"
        w="100%"
        h="auto"
        onClick={onClick}
        isDisabled={isCreditFullyUsed}
      >
        <Box width="full">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            pb="16px"
          >
            <Icon
              as={MdOutlineShoppingCart}
              width="25px"
              height="25px"
              color="RGB(31,77,162)"
            />
          </Box>
          <Text
            fontWeight="bold"
            fontSize="lg"
            textAlign="left"
            lineHeight="150%"
          >
            Local
          </Text>
          <Text
            fontWeight="medium"
            fontSize="0.7em"
            textAlign="left"
            lineHeight="150%"
            color={textColorPrimary}
          >
            Order from local suppliers
          </Text>
        </Box>
      </Button>
    </Tooltip>
  );

  const USDButton = () => {
    return (
      <Tooltip>
        <Button
          borderWidth={1}
          backgroundColor="#E7F2FF"
          w="100%"
          p="24px"
          h="auto"
          onClick={() => navigate("/dashboard/usdPayments")}
        >
          <Box width="full">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              pb="16px"
            >
              <Icon
                as={MdLanguage}
                width="25px"
                height="25px"
                color="RGB(31,77,162)"
              />
              {companyCountry === CountryEnum.Kenya && (
                <Box
                  bgColor="#DEF6E9"
                  alignSelf="start"
                  textColor="#439C6C"
                  py="6px"
                  px="8px"
                  borderRadius="16px"
                >
                  <Text fontWeight="normal" fontSize="10px" lineHeight="100%">
                    $1 = KES {exchangeRate}
                  </Text>
                </Box>
              )}
            </Box>
            <Text
              fontWeight="bold"
              fontSize="lg"
              textAlign="left"
              lineHeight="150%"
            >
              International
            </Text>
            <Text
              fontWeight="medium"
              fontSize="0.7em"
              textAlign="left"
              lineHeight="150%"
              color={textColorPrimary}
            >
              Order from international suppliers
            </Text>
          </Box>
        </Button>
      </Tooltip>
    );
  };

  const LocalPurchaseButton = () => {
    return (
      <FlexPayButton
        onClick={() => navigate("/dashboard/paymentRequest")}
        isDisabled={false}
      />
    );
  };

  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  return (
    <Card
      mb={{ base: "0px", "2xl": "20px" }}
      px={"20px"}
      pt="30px"
      pb="110px"
      {...rest}
    >
      <Text
        fontSize="xl"
        fontWeight="medium"
        color={textColorPrimary}
        mb="16px"
      >
        {companyName}
      </Text>
      <SimpleGrid columns={columns} gap="20px" mb={10}>
        <Information
          boxShadow={cardShadow}
          title="Your Credit Limit"
          value={
            (companyCountry === CountryEnum.Kenya ? "KES " : "RWF ") +
            formatCurrency(parseFloat(creditLimit.toString()))
          }
        />
        <Information
          boxShadow={cardShadow}
          title="Amount Due"
          value={
            (companyCountry === CountryEnum.Kenya ? "KES " : "RWF ") +
            formatCurrency(parseFloat(amountDue.toString()))
          }
          textValueColorPrimary={amountDue > 0 && isDue ? "red.500" : undefined}
        />
        <Information
          boxShadow={cardShadow}
          title="Due Date"
          value={dueDate}
          textValueColorPrimary={
            dueDate !== "-" && isDue ? "red.500" : undefined
          }
        />
      </SimpleGrid>
      <Text fontSize="lg" color={textColorPrimary} mb={3}>
        What would you like to do?
      </Text>
      <SimpleGrid columns={columnsOptions} gap="40px" mb={10}>
        <USDButton />
        <LocalPurchaseButton />
      </SimpleGrid>
    </Card>
  );
}
