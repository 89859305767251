import { Box, Button, Flex, FormControl, Text } from "@chakra-ui/react";
import { formatCurrency } from "../../../../lib/utilities";
import React, { useRef, useState } from "react";
import { PaymentRequestDto } from "../../../../generated-client/model/payment-request-dto";
import TypeEnum = FileDto.TypeEnum;
import { FileDto } from "../../../../generated-client/model/file-dto";
import { SupplierDto } from "../../../../generated-client/model/supplier-dto";
import StatusEnum = PaymentRequestDto.StatusEnum;
import MobilePayModal from "./MobilePayModal";
import PaymentDetailsModal from "./PaymentDetails";
import FlexPayPaymentDetailsModal from "./FlexPayPaymentDetails";
import { CompanyDto } from "../../../../generated-client/model/company-dto";
import CountryEnum = CompanyDto.CountryEnum;

interface TableComponentProps {
  paymentRequests: PaymentRequestDto[];
  sendFile: (file: File, paymentRequestId: string) => void;
  handlePurchaseOrderDownload: (request: PaymentRequestDto) => void;
  loadingStates: { [key: string]: boolean };
  setRowLoading: (id: string, isLoading: boolean) => void;
  downloadFile: (id: string) => void;
  suppliers: SupplierDto[];
  country: CountryEnum;
}

export default function CardLayout({
  paymentRequests,
  suppliers,
  setRowLoading,
  downloadFile,
  sendFile,
  handlePurchaseOrderDownload,
  loadingStates,
  country,
}: TableComponentProps) {
  const [currentRequestId, setCurrentRequestId] = useState<string | null>(null); // New state to keep track of the current directPaymentId
  let fileInputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // New state to manage modal visibility
  const [isFlexPayRepayModalOpen, setIsFlexPayRepayModalOpen] = useState(false); // New state to manage modal visibility
  const [modalPayment, setModalPayment] = useState<PaymentRequestDto>(null); // New state to manage the payment details for the modal
  const [isFlexPayModalOpen, setIsFlexPayModalOpen] = useState(false);

  const toggleModal = (payment: PaymentRequestDto) => {
    setModalPayment(payment);
    setIsModalOpen((prevState) => !prevState);
  };

  const toggleFlexPayModal = async (payment?: PaymentRequestDto) => {
    setModalPayment(payment);
    setIsFlexPayModalOpen((prevState) => !prevState);
  };

  const handleFileUpload = async (file: File, paymentRequestId: string) => {
    setRowLoading(paymentRequestId, true); // Set loading state
    await sendFile(file, paymentRequestId); // Assume sendFile is defined somewhere
    setRowLoading(paymentRequestId, false); // Clear loading state
  };

  const handleFileChange = (event: any) => {
    if (!currentRequestId) return; // Exit early if no current directPaymentId

    const file = event.target.files[0];
    handleFileUpload(file, currentRequestId);
  };

  const triggerFileInput = (directPaymentId: string) => {
    setCurrentRequestId(directPaymentId); // Set the current directPaymentId when the button is clicked
    fileInputRef.current.click();
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {paymentRequests?.map((request) => (
        <Box
          key={request.id}
          bg="white"
          p={5}
          shadow="md"
          borderWidth="1px"
          mb={4}
        >
          <Text fontSize="xl">{request.supplierName}</Text>

          {/* Info section */}
          <Box mb={4} fontSize="0.9em">
            <Text>
              Amount:{" "}
              {request.amountCurrency + " " + formatCurrency(request.amount)}
            </Text>
            <Text>
              Amount to Repay:{" "}
              {request.amountToRepayCurrency +
                " " +
                formatCurrency(request.amountToRepay)}
            </Text>
            <Text>
              Due Date:{" "}
              {new Date(request.dueDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </Text>
            <Text>Status: {request.status.replace("_", " ")}</Text>
          </Box>

          {/* Info section */}
          <Box mb={4} fontSize="0.9em">
            <Text>
              Amount:{" "}
              {request.amountCurrency + " " + formatCurrency(request.amount)}
            </Text>
            {request.amount - request.amountCredit > 0 && (
              <>
                <Text>
                  Pay now:{" "}
                  {request.amountCurrency +
                    " " +
                    formatCurrency(request.amount - request.amountCredit)}
                </Text>
                <Box
                  onClick={() => toggleModal(request)}
                  mr={2} // Margin to separate the buttons
                  cursor="pointer" // Change cursor to pointer on hover
                  display="flex" // Use flex to align the text and icon
                  alignItems="center" // Align items to the center vertically
                >
                  <Text fontSize="sm" color="grey">
                    Payment account details
                  </Text>
                </Box>
              </>
            )}
            {request.amountCredit > 0 && (
              <>
                <Text>
                  Flex pay:{" "}
                  {request.amountCreditCurrency +
                    " " +
                    formatCurrency(request.amountCredit)}
                </Text>
                <Text>
                  Due date:{" "}
                  {new Date(request.dueDate).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </Text>
                <Box
                  onClick={() => toggleFlexPayModal(request)} // New box to open the modal with payment details
                  mr={2} // Margin to separate the buttons
                  cursor="pointer" // Change cursor to pointer on hover
                  display="flex" // Use flex to align the text and icon
                  alignItems="center" // Align items to the center vertically
                >
                  <Text fontSize="sm" color="grey">
                    Flex pay repayment details
                  </Text>
                </Box>
              </>
            )}
            <Text>
              Due Date:{" "}
              {new Date(request.dueDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </Text>
            <Text>Status: {request.status.replace("_", " ")}</Text>
          </Box>

          {/* Items section */}
          <Box w="100%">
            <Text mb={2}>Order:</Text>
            {request.items.map((item) => (
              <Box key={item.name} mb={2}>
                <Text>
                  - {item.name}: {formatCurrency(item.quantity)} *{" "}
                  {request.amountCurrency} {formatCurrency(item.unitPrice)} ={" "}
                  {request.amountCurrency} {formatCurrency(item.total)}
                </Text>
              </Box>
            ))}
          </Box>

          <Button
            isLoading={loadingStates[request.id]}
            variant="unstyled"
            pt={"5px"}
            onClick={() => handlePurchaseOrderDownload(request)}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="auto"
          >
            <Text fontSize="sm" color="black" fontWeight="bold">
              View Purchase Order
            </Text>
          </Button>

          {!(request as any).files?.find(
            (f: any) => f.type === TypeEnum.ProofOfUserPayment
          ) ? (
            <FormControl>
              <input
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange} // No need to pass directPaymentId here
                accept="image/*, .pdf, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .jpg, .png, .jpeg"
              />
              <Button
                onClick={() => triggerFileInput(request.id)} // Pass directPaymentId to triggerFileInput
                isLoading={loadingStates[request.id]}
              >
                <Text fontSize="sm" color="grey">
                  Upload proof <br /> of payment
                </Text>
              </Button>
            </FormControl>
          ) : (
            <>
              {request.files?.map((file, index) => (
                <Button
                  fontSize="sm"
                  color="grey"
                  key={index}
                  onClick={() => downloadFile(file.key)}
                >
                  PROOF OF PAYMENT
                </Button>
              ))}
            </>
          )}

          {request.amountCredit > 0 &&
            [
              StatusEnum.PastDue,
              StatusEnum.PaymentSubmitted,
              StatusEnum.InvoiceSent,
            ].includes(request.status) && (
              <Flex width="100%" justifyContent="left">
                {" "}
                {/* Use Flex to center the button */}
                <Button
                  colorScheme="blue"
                  mt="10px"
                  onClick={() => {
                    setModalPayment(request);
                    setIsFlexPayRepayModalOpen(true);
                  }}
                >
                  Repay Flex pay
                </Button>
              </Flex>
            )}
        </Box>
      ))}
      {modalPayment && (
        <PaymentDetailsModal
          isOpen={isModalOpen}
          paymentRequest={modalPayment}
          currency={modalPayment.amountCurrency}
          supplier={suppliers.find((s) => s.id === modalPayment.supplierId)}
          onClose={() => toggleModal(null)}
        />
      )}
      {modalPayment && (
        <FlexPayPaymentDetailsModal
          isOpen={isFlexPayModalOpen}
          paymentRequest={modalPayment}
          currency={modalPayment.amountToRepayCurrency}
          onClose={() => toggleFlexPayModal(null)}
          country={country}
        />
      )}
      {modalPayment && (
        <MobilePayModal
          isOpen={isFlexPayRepayModalOpen}
          onClose={() => setIsFlexPayRepayModalOpen(false)}
          paymentRequest={modalPayment}
          currency={modalPayment.amountCreditCurrency}
        />
      )}
    </Box>
  );
}
