/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _|
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box } from '@chakra-ui/react';
// Assets


import { useAuth0 } from "@auth0/auth0-react";
import General from "./components/General";
import {useEffect, useState} from "react";
import {getCompany} from "../../../services/company";
import {useNavigate} from "react-router-dom";
import {CompanyDto} from "../../../generated-client/model/company-dto";
import {formatCurrency} from "../../../lib/utilities";
import {getPaymentRequests} from "../../../services/payment-request";
import {PaymentRequestDto} from "../../../generated-client/model/payment-request-dto";
import StatusEnum = PaymentRequestDto.StatusEnum;
import {getExchangeRate} from "../../../services/finance";
import {ExchangeRateDto} from "../../../generated-client/model/exchange-rate-dto";
import * as Sentry from "@sentry/react";

export default function UserReports() {
	const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
	const [companyData, setCompanyData] = useState<CompanyDto>(null);
	const [amountDue, setAmountDue] = useState<number>(0);
	const [dueDate, setDueDate] = useState<Date>(null);
	const [exchangeRate, setExchangeRate] = useState<string>('');
	const navigate = useNavigate();


	async function fetchData() {
		try {
			const accessToken = await getAccessTokenSilently();
			const data = await getCompany(accessToken);
			const paymentRequests = await getPaymentRequests(accessToken);
			const closestPaymentRequestDue = paymentRequests.paymentRequests.filter(p => [StatusEnum.Approved, StatusEnum.PaymentSubmitted, StatusEnum.PastDue].includes(p.status) && p.amountCredit > 0 && p.amountToRepay > 0).sort((a, b) => {
				return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
			});
			if(closestPaymentRequestDue.length > 0) {
				setAmountDue(closestPaymentRequestDue[0]?.amountToRepay);
				setDueDate(new Date(closestPaymentRequestDue[0]?.dueDate));
			}

			// If you need further validations, add them here.
			setCompanyData(data.company);

			const fxRateSupplier = await getExchangeRate(accessToken, ExchangeRateDto.FromEnum.Usd, ExchangeRateDto.ToEnum.KesSupplier);
			const fxRateOther = await getExchangeRate(accessToken, ExchangeRateDto.FromEnum.Usd, ExchangeRateDto.ToEnum.KesOther);
			setExchangeRate(formatCurrency(fxRateSupplier.exchangeRate.value < fxRateOther.exchangeRate.value ? fxRateSupplier.exchangeRate.value : fxRateOther.exchangeRate.value));

			console.log({fxRateOther, fxRateSupplier});
			if(data.company.name === null ||
				data.company.address === null ||
				data.company.registrationNumber === null) {
				navigate('/dashboard/profile');
			}
		} catch (error) {
			console.error('Error fetching company data:', error);
		}
	}

	useEffect(() => {
		if (isLoading) {
			// Wait until Auth0 has determined the user's authentication status.
			return;
		}

		if (isAuthenticated) {
			Sentry.captureEvent({message: 'B User is authenticated' + isAuthenticated + ' ' + user?.email});
			// If the user is authenticated, fetch the company data.
			fetchData();
		} else {
			Sentry.captureEvent({message: 'B User is not authenticated' + isAuthenticated + ' ' + user?.email});
			navigate("/auth/sign-in");
		}
	}, [isLoading, isAuthenticated]);

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			{companyData ? (
				<General
					companyName={companyData.name}
					companyCountry={companyData.country}
					companyAddress={companyData.address}
					creditLimit={companyData.creditInformation?.creditLimit || 0}
					amountDue={amountDue}
					exchangeRate={exchangeRate}
					dueDate={dueDate?.toLocaleDateString('en-US', {
						year: 'numeric',
						month: 'long',
						day: 'numeric'
					}) || '-'}
					gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }} minH='365px' pe='20px'
					isDue={new Date(dueDate) < new Date()}
				/>
			) : (
				<p>Loading...</p>
			)}
		</Box>
	);
}
