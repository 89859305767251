import {
  Button,
  Flex,
  Input,
  Select,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import TableComponent from "./components/Table";
import CardLayout from "./components/CardLayout";

import { useFetchSuppliers } from "./hooks/FetchSuppliers";
import { CompanyDto } from "../../../generated-client/model/company-dto";
import CountryEnum = CompanyDto.CountryEnum;
import Pagination from "../sharedComponents/pagination";
import SupplierModal from "./components/SupplierModal";
import ProductModal from "./components/ProductModal";
import EditSupplierModal from "./components/EditSupplier";

export default function Suppliers() {
  const { suppliers, fetchData } = useFetchSuppliers();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;
  const isTableLayout = useBreakpointValue({ base: false, lg: true });
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("All");
  const [isNewSuplierModalOpen, setIsNewSuplierModalOpen] = useState(false);
  const [isEditSuplierModalOpen, setIsEditSuplierModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [supplierId, setSupplierId] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setCurrentPage(1);
    }, 2000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, selectedCountry]);

  const filteredSuppliers = suppliers.filter((c) => {
    return (
      c.name?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) &&
      (selectedCountry === "All" || c.country === selectedCountry)
    );
  });

  useEffect(() => {
    fetchData(
      (currentPage - 1) * pageSize,
      pageSize,
      debouncedSearchTerm,
      selectedCountry === "All" ? null : (selectedCountry as CountryEnum)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, debouncedSearchTerm, selectedCountry, refresh]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Flex
        alignItems="center"
        gap="2"
        direction={{ base: "column", md: "row" }}
        pt={{ base: "50px", md: "30px", xl: "30px" }}
      >
        {" "}
        {/* Adjust the gap as needed */}
        <Input
          placeholder="Search by supplier name..."
          onChange={(e) => setSearchTerm(e.target.value)}
          w="300px"
          bg="white"
          borderColor="gray.300"
          _hover={{ borderColor: "gray.400" }}
          _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
        />
        <Select
          onChange={(e) => setSelectedCountry(e.target.value)}
          value={selectedCountry}
          w="200px"
          bg="white"
          borderColor="gray.300"
          _hover={{ borderColor: "gray.400" }}
          _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
        >
          <option value="All">All</option>
          <option value="Rwanda">Rwanda</option>
          <option value="Kenya">Kenya</option>
        </Select>
        <Button
          colorScheme="blue"
          onClick={() => setIsNewSuplierModalOpen(true)}
        >
          Add new supplier
        </Button>
      </Flex>
      {isTableLayout ? (
        <TableComponent
          suppliers={filteredSuppliers}
          openEditSupplierModal={(value) => {
            setSupplierId(value);
            setIsEditSuplierModalOpen(true);
          }}
        />
      ) : (
        <CardLayout
          suppliers={filteredSuppliers}
          openEditSupplierModal={(value) => {
            setSupplierId(value);
            setIsEditSuplierModalOpen(true);
          }}
        />
      )}
      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        elementsCount={filteredSuppliers.length}
        onPageChange={handlePageChange}
      />

      <EditSupplierModal
        isOpen={isEditSuplierModalOpen}
        onClose={() => {
          setIsEditSuplierModalOpen(false);
          // setRefresh(true);
        }}
        supplierId={supplierId}
      />

      <SupplierModal
        isOpen={isNewSuplierModalOpen}
        onClose={() => {
          setIsNewSuplierModalOpen(false);
        }}
        updateRefresh={(value: boolean) => {
          setRefresh(value);
        }}
      />
    </>
  );
}
