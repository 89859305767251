import {
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Text,
  Checkbox,
  Flex,
  Tooltip,
  Box,
  RadioGroup,
  Radio,
  Divider,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useState, useEffect, useRef } from "react";
import { formatCurrency, roundNumber } from "../../../lib/utilities";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getCompany } from "../../../services/company";
import {
  createPaymentRequest, fetchRepaymentTerms,
  getPaymentRequests,
} from "../../../services/payment-request";
import { RepaymentTerms } from "./components/RepaymentsTerms";
import { AmountInput } from "./components/AmountInput";
import { ItemsList } from "./components/ItemsList";
import { VStack } from "@chakra-ui/react";
import ConfirmationModal from "./components/ConfirmationModal";
import SuccessModal from "./components/SuccessModal";
import { useTranslation } from "react-i18next";
import { CompanyDto } from "../../../generated-client/model/company-dto";
import { getUser } from "../../../services/user";
import { UserDto } from "../../../generated-client/model/user-dto";
import RoleEnum = UserDto.RoleEnum;
import { CreatePaymentRequestRequest } from "../../../generated-client/model/create-payment-request-request";
import AmountCurrencyEnum = CreatePaymentRequestRequest.AmountCurrencyEnum;
import PayNowModal from "./components/PayNowModal";
import { uploadFile } from "../../../services/file";
import { attachProofOfUserPayment } from "../../../services/payment-request";
import { SupplierDto } from "../../../generated-client/model/supplier-dto";
import { getSuppliers } from "../../../services/supplier";
import { PaymentRequestDto } from "../../../generated-client/model/payment-request-dto";
import StatusEnum = PaymentRequestDto.StatusEnum;
import MobilePaySuccessModal from "./components/MobilePaySuccessModal";
import CountryEnum = CompanyDto.CountryEnum;
import { CreateSupplierRequest } from "../../../generated-client/model/create-supplier-request";
import { PaymentOptionsBlock } from "components/PaymentOptionsBlock";
import { RepaymentTermsCalculateResponse } from "../../../generated-client/model/repayment-terms-calculate-response";

export interface Item {
  name: string;
  quantity: number;
  unitPrice: number;
  total: number;
}

// Helper function to round to two decimals
const roundToTwoDecimals = (num: number) => Math.round(num * 100) / 100;

const CONFIG = {
  DUE_DATE: {
    EXCLUDE_WEEKENDS_FROM_CALCULATION: false,
    RESOLUTION_MATRIX: {
      [CountryEnum.Rwanda]: {
        byCategory: {
          [CreateSupplierRequest.CategoryEnum.FoodAndBeverage]: 7,
          default: 31,
        }
      },
      default: {
        default: 5,
      }
    }
  }
}

export default function PaymentRequestPage() {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const creditAmountInputRef = useRef(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [error, setError] = useState(null);
  const [suppliers, setSuppliers] = useState<SupplierDto[]>([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [supplierId, setSupplierId] = useState("");
  const [selectedSupplierCategory, setSelectedSupplierCategory] = useState(null);
  const [, setPaymentAmount] = useState(0);
  const [transactionFee, setTransactionFee] = useState(0);
  const [totalRepay, setTotalRepay] = useState(0);
  const [creditAmountError, setCreditAmountError] = useState<string | null>(
    null
  );
  const [creditLimit, setCreditLimit] = useState<number>();
  const [, setIsUserAdmin] = useState(false);
  const [companyData, setCompanyData] = useState<CompanyDto>();
  const [feePercentage, setFeePercentage] = useState<number>(0);
  const [creditAmount, setCreditAmount] = useState<number>(0);
  const [formattedRecommendAmount, setFormattedRecommendAmount] =
    useState<string>("0");
  const [recommendAmountWidth, setRecommendAmountWidth] =
    useState<string>("200px");
  const [paymentType, setPaymentType] = useState<string>("bankTransfer");
  const [dueDate, setDueDate] = useState("");
  const [currency, setCurrency] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPayNowModalOpen, setIsPayNowModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isMobilePaySuccessModalOpen, setIsMobilePaySuccessModalOpen] =
    useState(false);
  const [items, setItems] = useState<Item[]>([
    { name: "", quantity: 0, unitPrice: 0, total: 0 },
  ]);
  const [isSubmissionPending, setIsSubmissionPending] = useState(false);
  const [isDue, setIsDue] = useState(false);
  const [amountDue] = useState<number>(0);
  const [flexPayBoxWidth, setflexPayBoxWidth] = useState(0);
  const [calculatedRepaymentTerms, setCalculatedRepaymentTerms] = useState<RepaymentTermsCalculateResponse>(undefined);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const flexPayBoxRef = useRef(null);

  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const isValidForm = (): boolean => {
    if (isDue) {
      setError(t("flex_pay_repayment_past_due"));
      setIsSubmitLoading(false);
      return false;
    }
    // Validate the items
    for (const item of items) {
      if (item.name.trim() === "" || item.quantity === 0) {
        setError(t("items_error"));
        setIsSubmitLoading(false);
        return false; // Exit early to prevent form submission
      }
    }

    // Validate the credit amount
    if (paymentType === "flexPay" && creditAmount <= 0) {
      setError(t("credit_amount_greater_than_zero"));
      setIsSubmitLoading(false);
      return false; // Exit early to prevent form submission
    }

    // Validate the credit amount
    if (creditAmount > totalAmount) {
      setError(t("credit_amount_less_or_equal_to_total_amount"));
      setIsSubmitLoading(false);
      return false; // Exit early to prevent form submission
    }

    if (supplierId === "") {
      setError(t("choose_a_supplier"));
      setIsSubmitLoading(false);
      return false; // Exit early to prevent form submission
    }

    // Validate terms acceptance
    if (paymentType === "flexPay" && !termsAccepted) {
      setError(t("accept_terms_and_conditions"));
      setIsSubmitLoading(false);
      return false; // Exit early to prevent form submission
    }

    const creditAmountValid = validateCreditAmountInput(creditAmount);
    if (!creditAmountValid) {
      setIsSubmitLoading(false);
      return false;
    }

    return true;
  };

  const recalculateTotal = () => {
    let total = 0;
    items.map((i) => (total = total + i.total));

    total = roundNumber(total);

    setTotalAmount(total);
  };

  const handleSubmit = () => {
    setError("");
    if (!isValidForm()) {
      return;
    }
    openModal();
  };

  const handleSupplierCategoryChange = (newCat: string) => {
    setSelectedSupplierCategory(newCat);
  }

  const updateDividerWidth = () => {
    if (flexPayBoxRef.current) {
      setflexPayBoxWidth(flexPayBoxRef.current.clientWidth);
    }
  };

  async function fetchData() {
    try {
      const accessToken = await getAccessTokenSilently();
      // TODO: improve performance (parallel requests)
      const data = await getCompany(accessToken);
      const user = await getUser(accessToken);
      const suppliers = await getSuppliers(accessToken);
      const paymentRequests = await getPaymentRequests(accessToken);
      const closestPaymentRequestDue = paymentRequests.paymentRequests
        .filter(
          (p) =>
            [
              StatusEnum.Approved,
              StatusEnum.PaymentSubmitted,
              StatusEnum.PastDue,
            ].includes(p.status) &&
            p.amountCredit > 0 &&
            p.amountToRepay > 0
        )
        .sort((a, b) => {
          return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
        });

      if (closestPaymentRequestDue.length > 0) {
        setIsDue(
          new Date(closestPaymentRequestDue[0]?.dueDate) < new Date() &&
            closestPaymentRequestDue[0]?.amountToRepay > 0
        );
      }

      setCreditLimit(data.company.creditInformation?.creditLimit || 0);
      setFeePercentage(data.company.feePercentage || 0);
      if (user.user.role === RoleEnum.Admin) {
        setIsUserAdmin(true);
      }
      setCompanyData(data.company);
      setSuppliers(suppliers.suppliers);
      setCurrency(
        data.company.country === "Kenya"
          ? AmountCurrencyEnum.Kes
          : AmountCurrencyEnum.Rwf
      );
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  }

  const fetchRepaymentTermsOnDemand = async () => {
    const accessToken = await getAccessTokenSilently();
    const repaymentTerms = await fetchRepaymentTerms({ supplierId }, accessToken);
    setCalculatedRepaymentTerms(repaymentTerms);
    return repaymentTerms;
  }

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isAuthenticated) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    // attach event listener and update divider width only when the tab selected is pay a supplier
    if (companyData) {
      updateDividerWidth();

      // update the box divider width on window resize or focus
      window.addEventListener("resize", updateDividerWidth);
      window.addEventListener("focus", updateDividerWidth);

      // clean up the event listeners
      return () => {
        window.removeEventListener("resize", updateDividerWidth);
        window.removeEventListener("focus", updateDividerWidth);
      };
    }
  }, [companyData]);

  useEffect(() => {
    if (selectedSupplierCategory === "FMCG" && supplierId) {
      fetchRepaymentTermsOnDemand();
    }
  }, [selectedSupplierCategory])

  const handleItemNameChange = (
    index: number,
    field: keyof Item,
    value: string
  ) => {
    const updatedItems = [...items];
    updatedItems[index]["name"] = value;
    setItems(updatedItems);
  };

  const handleItemUnitPriceChange = (
    index: number,
    field: keyof Item,
    value: number
  ) => {
    const updatedItems = [...items];
    updatedItems[index]["unitPrice"] = value;
    updatedItems[index]["total"] =
      updatedItems[index]["quantity"] * updatedItems[index]["unitPrice"];
    setItems(updatedItems);
    recalculateTotal();
  };

  const handleItemQuantityChange = (
    index: number,
    field: keyof Item,
    value: number
  ) => {
    const updatedItems = [...items];
    updatedItems[index]["quantity"] = value;
    updatedItems[index]["total"] = roundNumber(
      updatedItems[index]["quantity"] * updatedItems[index]["unitPrice"]
    );
    setItems(updatedItems);
    recalculateTotal();
  };

  const addItemRow = () => {
    setItems([...items, { name: "", quantity: 0, unitPrice: 0, total: 0 }]);
  };

  const submitToPayNow = async () => {
    setIsModalOpen(false); // Close the modal
    setIsPayNowModalOpen(true);
    setError(null);
  };

  const finalSubmit = async (
    paymentMethod: CreatePaymentRequestRequest.PaymentMethodEnum,
    file?: File,
    isMobilePay?: boolean
  ) => {
    setIsSubmitLoading(true);
    setError(null);
    if (
      file ||
      paymentMethod === CreatePaymentRequestRequest.PaymentMethodEnum.OnDelivery
    ) {
      setIsSubmissionPending(true);
    }

    try {
      const accessToken = await getAccessTokenSilently();

      const response = await createPaymentRequest(accessToken, {
        amount: totalAmount,
        amountInSecondaryCurrency: 0,
        supplierId: supplierId,
        amountCurrency:
          currency as CreatePaymentRequestRequest.AmountCurrencyEnum,
        amountInSecondaryCurrencyCurrency:
          currency as CreatePaymentRequestRequest.AmountCurrencyEnum,
        amountCredit: paymentType === "flexPay" ? creditAmount : 0,
        durationInDays:
          paymentType === "flexPay"
            ? companyData.country === CountryEnum.Rwanda
              ? 8
              : 5
            : 0,
        type:
          paymentType === "flexPay"
            ? CreatePaymentRequestRequest.TypeEnum.FlexPay
            : CreatePaymentRequestRequest.TypeEnum.PayNow,
        paymentMethod: paymentMethod,
        items: items,
        supplierName:
          suppliers.find((s) => s.id === supplierId)?.friendlyName || "",
        termsAccepted: true,
      });

      if (file) {
        const fileUploaded = await uploadFile(
          accessToken,
          {
            paymentRequestId: response.paymentRequestId,
            type: "PROOF_OF_USER_PAYMENT",
          },
          file
        );

        await attachProofOfUserPayment(accessToken, {
          id: response.paymentRequestId,
          fileId: fileUploaded.id,
        });
      }
      setIsPayNowModalOpen(false); // Close the modal
      setIsModalOpen(false);
      if (isMobilePay) {
        setIsMobilePaySuccessModalOpen(true);
      }
      if (!isMobilePay) {
        setIsSuccessModalOpen(true);
      }
    } catch (err) {
      setError(t("failed_to_submit_payment_request"));
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const removeItemRow = (index: number) => {
    if (items.length > 1) {
      const updatedItems = [...items];
      updatedItems.splice(index, 1);
      setItems(updatedItems);
      recalculateTotal();
    }
  };

  useEffect(() => {
    if (paymentType === "bankTransfer") {
      setCreditAmount(0);
    }
    const supplier = suppliers.find((s) => s.id === supplierId);

    if (supplier?.country === CountryEnum.Rwanda && supplier?.category) {
      if (supplier?.category !== CreateSupplierRequest.CategoryEnum.FoodAndBeverage) {
        setFeePercentage(0.035);
      } else if (supplier?.category === CreateSupplierRequest.CategoryEnum.FoodAndBeverage) {
        setFeePercentage(0.01);
      } else {
        setFeePercentage(companyData?.feePercentage || 0);
      }
    }

    const fee = Math.ceil(roundToTwoDecimals(creditAmount * feePercentage));
    const totalRepayToSet = roundToTwoDecimals(creditAmount + fee);
    setTransactionFee(fee);
    setPaymentAmount(totalAmount);
    setTotalRepay(totalRepayToSet);

    let defaultDueDateDays = 5;

    if (supplier?.country === CountryEnum.Rwanda) {
      if (
        supplier?.category ===
        CreateSupplierRequest.CategoryEnum.FoodAndBeverage
      ) {
        // NOTE: prev was 8 days (+1 day was to account for delivery)
        defaultDueDateDays = 7;
      } else {
        defaultDueDateDays = 31;
      }
    }

    const dueDate = new Date(
      new Date().getTime() + defaultDueDateDays * 24 * 60 * 60 * 1000
    );

    if (CONFIG.DUE_DATE.EXCLUDE_WEEKENDS_FROM_CALCULATION) {
      const dayOfWeek = dueDate.getDay();
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        const daysToAdd = dayOfWeek === 0 ? 1 : 2;
        dueDate.setDate(dueDate.getDate() + daysToAdd);
      }
    }

    const formattedDate = dueDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    setDueDate(formattedDate);

    const formattedValue =
      creditLimit < totalAmount ? creditLimit : totalAmount;
    setFormattedRecommendAmount(formatCurrency(formattedValue));
    const averageCharWidth = 10; // Adjust this based on your font
    const inputWidth = `${
      formattedValue.toString().length * averageCharWidth + 30
    }px`;
    setRecommendAmountWidth(inputWidth);
  }, [
    totalAmount,
    paymentType,
    creditAmount,
    feePercentage,
    supplierId,
    suppliers,
    creditLimit,
    companyData?.feePercentage,
  ]);

  const validateCreditAmountInput = (val: number) => {
    if (val > creditLimit) {
      setError(t("amount_cannot_be_higher_than_credit_limit"));
      setCreditAmountError(t("amount_cannot_be_higher_than_credit_limit"));
      return false;
    }
    if (val > totalAmount) {
      setError(t("amount_cannot_be_higher_than_total_amount"));
      setCreditAmountError(t("amount_cannot_be_higher_than_total_amount"));
      return false;
    }
    setCreditAmountError(null);
    return true;
  };

  if (!companyData) {
    return <div>Loading...</div>; // or return a loading spinner component
  }

  // Check if country of the company is not Kenya, show coming soon
  // if (companyData.country !== "Rwanda" && !isUserAdmin) {
  //   return (
  //     <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
  //       <Card
  //         w={{ base: "100%", md: "100%" }}
  //         mb={{ base: "0px", "2xl": "20px" }}
  //         gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
  //         minH="100px"
  //         pe="20px"
  //       >
  //         {/*<Text fontSize="xl" fontWeight="bold" mb={4}>{'Not available'}</Text>*/}
  //         <Text mb={4}>{"Coming soon to " + companyData.country + "!"}</Text>
  //       </Card>
  //     </Box>
  //   );
  // }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        w={{ base: "100%", md: "90%", xl: "70%" }}
        mb={{ base: "0px", "2xl": "20px" }}
        gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
        minH="365px"
        pe="20px"
      >
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          {t("local_order")}
        </Text>
        <Text fontSize="md" fontWeight="medium" mb={4}>
          {t("available_credit_limit")} {currency}{" "}
          {creditLimit
            ? formatCurrency(parseFloat(creditLimit.toString()))
            : "-"}
        </Text>

        <ItemsList
          items={items}
          setSupplierId={setSupplierId}
          suppliers={suppliers}
          handleItemUnitPriceChange={handleItemUnitPriceChange}
          handleItemNameChange={handleItemNameChange}
          addItemRow={addItemRow}
          handleItemQuantityChange={handleItemQuantityChange}
          removeItemRow={removeItemRow}
          onSupplierCategoryChange={handleSupplierCategoryChange}
        />

        <SimpleGrid columns={1} spacing={1}>
          <AmountInput
            currency={currency}
            totalAmount={formatCurrency(totalAmount)}
          />
        </SimpleGrid>

        <Text fontWeight="bold" mb={2} pt={"20px"}>
          {t("payment")}
        </Text>

        <PaymentOptionsBlock
          onChange={setPaymentType}
          currency={currency}
          value={paymentType}
          totalAmount={totalAmount}
          creditLimit={creditLimit}
          radioGroupRef={flexPayBoxRef}
          radioGroupWidth={flexPayBoxWidth}
          shouldRenderFlexPay={creditLimit > 0}
        />

        {paymentType === "flexPay" && (
          <VStack
            spacing={4}
            align="start"
            w={["100%", "100%", "85%", "55%"]}
            pt="20px"
          >
            <FormControl>
              <FormLabel>{t("enter_amount_to_pay_on_credit")}</FormLabel>
              <InputGroup>
                <InputLeftAddon children={currency} />
                <Input
                  ref={creditAmountInputRef}
                  type="number"
                  onChange={(e) =>
                    validateCreditAmountInput(parseFloat(e.target.value))
                  }
                  max={200}
                  placeholder="0"
                  onBlur={(e) =>
                    setCreditAmount(
                      e.target.value ? parseFloat(e.target.value) : 0
                    )
                  }
                />
              </InputGroup>
              {creditAmountError && (
                <Text color="red.500" pt={"10px"} mb={4}>
                  {creditAmountError}
                </Text>
              )}
            </FormControl>
            <div style={{ cursor: "pointer" }}>
              <InputGroup size="sm" style={{ width: "auto" }}>
                <InputLeftAddon
                  children="+"
                  onClick={() => {
                    const newAmount =
                      creditLimit < totalAmount ? creditLimit : totalAmount;
                    setCreditAmount(newAmount);
                    if (creditAmountInputRef.current) {
                      creditAmountInputRef.current.value = newAmount;
                    }
                    validateCreditAmountInput(newAmount);
                  }}
                  style={{ cursor: "pointer" }}
                />
                <Input
                  style={{ cursor: "pointer", width: recommendAmountWidth }}
                  type="string"
                  value={formattedRecommendAmount}
                  isReadOnly
                  size="sm"
                />
              </InputGroup>
            </div>

            <Text mt={3} mb={1} fontWeight="bold">
              {t("repayment_terms")}
            </Text>

            <RepaymentTerms
              currency={currency}
              balanceToPayNow={totalAmount - creditAmount}
              dueDate={dueDate}
              paymentAmount={creditAmount}
              totalRepay={totalRepay}
              transactionFee={transactionFee}
            />
            <br />

            <Text w={["100%", "100%", "100%", "180%"]}>
              {t("terms_1")}
              <br />
              <br />

              {companyData.country === CountryEnum.Rwanda
                ? t("terms_2_rwanda") +
                  (supplierId
                    ? suppliers.find((s) => s.id === supplierId)?.friendlyName
                    : "the selected Supplier") +
                  "."
                : t("terms_2_kenya") +
                  (supplierId
                    ? suppliers.find((s) => s.id === supplierId)?.friendlyName
                    : "the selected Supplier") +
                  "."}
            </Text>

            <Checkbox
              w={["100%", "100%", "100%", "200%"]}
              mt={6}
              isChecked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
            >
              {t("terms_3")}
            </Checkbox>
          </VStack>
        )}

        <Flex justifyContent="flex-start" mt={4}>
          {error && (
            <Text color="red.500" mt={2}>
              {error}
            </Text>
          )}
        </Flex>

        <Flex mt={4}>
          {![].includes(companyData.name) ? (
            <Tooltip
              label={amountDue > 0 ? t("flex_pay_repayment_past_due") : ""}
            >
              <Button
                colorScheme={isDue ? "gray" : "blue"}
                mr={3}
                onClick={handleSubmit}
                isLoading={isLoading}
                disabled={isDue}
              >
                {t("next")}
              </Button>
            </Tooltip>
          ) : (
            <>
              <Tooltip label={t("acccount_not_approved_for_ordering")}>
                <Button
                  colorScheme="blue"
                  mr={3}
                  isLoading={isLoading}
                  isDisabled
                >
                  {t("next")}
                </Button>
              </Tooltip>
            </>
          )}
        </Flex>
      </Card>
      <ConfirmationModal
        isOpen={isModalOpen}
        items={items}
        currency={currency}
        totalAmount={totalAmount}
        fee={transactionFee}
        dueDate={dueDate}
        flexPayAmount={creditAmount}
        payNowAmount={totalAmount - creditAmount}
        supplier={
          suppliers.find((s) => s.id === supplierId)?.friendlyName || ""
        }
        onClose={() => setIsModalOpen(false)}
        onConfirm={() =>
          totalAmount - creditAmount <= 0
            ? finalSubmit(CreatePaymentRequestRequest.PaymentMethodEnum.Credit)
            : submitToPayNow()
        }
      />
      <ConfirmationModal
        isOpen={isModalOpen}
        items={items}
        currency={currency}
        totalAmount={totalAmount}
        fee={transactionFee}
        dueDate={dueDate}
        flexPayAmount={creditAmount}
        payNowAmount={totalAmount - creditAmount}
        supplier={
          suppliers.find((s) => s.id === supplierId)?.friendlyName || ""
        }
        onClose={() => setIsModalOpen(false)}
        onConfirm={() =>
          totalAmount - creditAmount <= 0
            ? finalSubmit(CreatePaymentRequestRequest.PaymentMethodEnum.Credit)
            : submitToPayNow()
        }
      />
      <PayNowModal
        isOpen={isPayNowModalOpen}
        currency={currency}
        isFinalSubmitLoading={isSubmitLoading}
        supplier={suppliers.find((s) => s.id === supplierId)}
        totalAmount={totalAmount}
        sendAmount={totalAmount - creditAmount}
        onClose={() => setIsPayNowModalOpen(false)}
        onConfirm={finalSubmit}
        paymentType={paymentType}
      />

      <SuccessModal
        isOpen={isSuccessModalOpen}
        isPending={!isSubmissionPending && totalAmount !== creditAmount}
        onClose={() => {
          setIsSuccessModalOpen(false);
          navigate("/dashboard/myPaymentRequests");
        }}
      />

      <MobilePaySuccessModal
        isOpen={isMobilePaySuccessModalOpen}
        onClose={() => {
          setIsMobilePaySuccessModalOpen(false);
          navigate("/dashboard/myPaymentRequests");
        }}
      />
    </Box>
  );
}
