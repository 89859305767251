import { Flex, Input, useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Pagination from "../sharedComponents/pagination";
import { useFetchProducts } from "./hooks/FetchProducts";
import TableComponent from "./components/Table";
import { useParams } from "react-router-dom";
import CardLayout from "./components/CardLayout";

export default function Products() {
  const { supplierName } = useParams<{ supplierName: string }>();
  const { products, fetchData } = useFetchProducts();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;
  const isTableLayout = useBreakpointValue({ base: false, lg: true });
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [refresh] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setCurrentPage(1);
    }, 2000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const filteredProducts = products.filter((c) => {
    return c.name?.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
  });

  useEffect(() => {
    fetchData({
      skip: (currentPage - 1) * pageSize,
      take: pageSize,
      supplierName:
        supplierName === ":supplierName"
          ? undefined
          : supplierName ?? debouncedSearchTerm,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, debouncedSearchTerm, refresh, supplierName]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Flex
        alignItems="center"
        gap="2"
        direction={{ base: "column", md: "row" }}
        pt={{ base: "50px", md: "30px", xl: "30px" }}
      >
        {" "}
        {/* Adjust the gap as needed */}
        <Input
          placeholder="Search by supplier name..."
          onChange={(e) => setSearchTerm(e.target.value)}
          w="300px"
          bg="white"
          borderColor="gray.300"
          _hover={{ borderColor: "gray.400" }}
          _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
          value={supplierName === ":supplierName" ? searchTerm : supplierName}
        />
      </Flex>
      {isTableLayout ? (
        <TableComponent products={filteredProducts} />
      ) : (
        <CardLayout products={filteredProducts} />
      )}
      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        elementsCount={filteredProducts.length}
        onPageChange={handlePageChange}
      />
    </>
  );
}
