import {
  Box,
  Button,
  Flex,
  FormControl,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import { formatCurrency } from "../../../../lib/utilities";
import { useRef, useState } from "react";
import { PaymentRequestDto } from "../../../../generated-client/model/payment-request-dto";
import { FileDto } from "../../../../generated-client/model/file-dto";
import TypeEnum = FileDto.TypeEnum;
import PaymentDetailsModal from "./PaymentDetails";
import FlexPayPaymentDetailsModal from "./FlexPayPaymentDetails";
import { SupplierDto } from "../../../../generated-client/model/supplier-dto";
import MobilePayModal from "./MobilePayModal";
import StatusEnum = PaymentRequestDto.StatusEnum;
import { CompanyDto } from "../../../../generated-client/model/company-dto";
import CountryEnum = CompanyDto.CountryEnum;

interface TableComponentProps {
  paymentRequests: PaymentRequestDto[];
  sendFile: (file: File, paymentRequestId: string) => void;
  handlePurchaseOrderDownload: (request: PaymentRequestDto) => void;
  setRowLoading: (id: string, isLoading: boolean) => void;
  downloadFile: (id: string) => void;
  loadingStates: { [key: string]: boolean };
  suppliers: SupplierDto[];
  country: CountryEnum;
}

export default function TableComponent({
  paymentRequests,
  suppliers,
  downloadFile,
  loadingStates,
  setRowLoading,
  sendFile,
  country,
  handlePurchaseOrderDownload,
}: TableComponentProps) {
  // const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({});
  const [currentRequestId, setCurrentRequestId] = useState<string | null>(null); // New state to keep track of the current directPaymentId
  let fileInputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // New state to manage modal visibility
  const [isFlexPayRepayModalOpen, setIsFlexPayRepayModalOpen] = useState(false); // New state to manage modal visibility
  const [modalPayment, setModalPayment] = useState<PaymentRequestDto>(null); // New state to manage the payment details for the modal
  const [isFlexPayModalOpen, setIsFlexPayModalOpen] = useState(false);

  const toggleModal = (payment: PaymentRequestDto) => {
    setModalPayment(payment);
    setIsModalOpen((prevState) => !prevState);
  };

  const handleFileUpload = async (file: File, paymentRequestId: string) => {
    setRowLoading(paymentRequestId, true); // Set loading state
    await sendFile(file, paymentRequestId); // Assume sendFile is defined somewhere
    setRowLoading(paymentRequestId, false); // Clear loading state
  };

  const handleFileChange = (event: any) => {
    if (!currentRequestId) return; // Exit early if no current directPaymentId

    const file = event.target.files[0];
    handleFileUpload(file, currentRequestId);
  };

  const triggerFileInput = (directPaymentId: string) => {
    setCurrentRequestId(directPaymentId); // Set the current directPaymentId when the button is clicked
    fileInputRef.current.click();
  };

  const toggleFlexPayModal = async (payment?: PaymentRequestDto) => {
    setModalPayment(payment);
    setIsFlexPayModalOpen((prevState) => !prevState);
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        w={{ base: "100%", md: "100%" }}
        mb={{ base: "0px", xl: "20px" }}
        minH="365px"
        pe="20px"
      >
        <Table variant="simple" size="md" fontSize="0.9em">
          <Thead>
            <Tr style={{ borderBottom: "2px solid #E2E8F0" }}>
              <Th>Supplier</Th>
              <Th>Order</Th>
              <Th>Payment details</Th>
              <Th>Status</Th>
              <Th>Files</Th>
            </Tr>
          </Thead>
          <Tbody>
            {paymentRequests?.map((request) => (
              <Tr
                key={request.id}
                style={{ borderBottom: "2px solid #E2E8F0" }}
              >
                <Td style={{ verticalAlign: "top" }}>{request.supplierName}</Td>
                <Td style={{ verticalAlign: "top" }}>
                  {request.items.map((item) => (
                    <Text key={item.name}>
                      {item.name}: {formatCurrency(item.quantity)} *{" "}
                      {item.name === "Shipping"
                        ? request.amountCurrency
                        : request.amountInSecondaryCurrencyCurrency}{" "}
                      {formatCurrency(item.unitPrice)} ={" "}
                      {item.name === "Shipping"
                        ? request.amountCurrency
                        : request.amountInSecondaryCurrencyCurrency}{" "}
                      {formatCurrency(item.total)}
                    </Text>
                  ))}
                  {!request.directPaymentId && (
                    <Button
                      isLoading={loadingStates[request.id]}
                      variant="unstyled"
                      pt={"5px"}
                      onClick={() => handlePurchaseOrderDownload(request)}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="auto"
                    >
                      <Text fontSize="sm" color="black" fontWeight="bold">
                        View Purchase Order
                      </Text>
                    </Button>
                  )}
                  {request.directPaymentId && <Text>USD payment</Text>}
                </Td>
                <Td style={{ verticalAlign: "top" }} minW="200px">
                  <Text>
                    Amount:{" "}
                    {request.amountCurrency +
                      " " +
                      formatCurrency(request.amount)}
                  </Text>
                  {request.amount - request.amountCredit > 0 && (
                    <>
                      <Text>
                        Pay now:{" "}
                        {request.amountCurrency +
                          " " +
                          formatCurrency(request.amount - request.amountCredit)}
                      </Text>
                      <Box
                        onClick={() => toggleModal(request)}
                        mr={2} // Margin to separate the buttons
                        cursor="pointer" // Change cursor to pointer on hover
                        display="flex" // Use flex to align the text and icon
                        alignItems="center" // Align items to the center vertically
                      >
                        <Text fontSize="sm" color="grey">
                          Payment account details
                        </Text>
                      </Box>
                    </>
                  )}
                  {request.amountCredit > 0 && (
                    <>
                      <Text>
                        Flex pay:{" "}
                        {request.amountCreditCurrency +
                          " " +
                          formatCurrency(request.amountCredit)}
                      </Text>
                      <Text>
                        Due date:{" "}
                        {new Date(request.dueDate).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </Text>
                      <Box
                        onClick={() => toggleFlexPayModal(request)} // New box to open the modal with payment details
                        mr={2} // Margin to separate the buttons
                        cursor="pointer" // Change cursor to pointer on hover
                        display="flex" // Use flex to align the text and icon
                        alignItems="center" // Align items to the center vertically
                      >
                        <Text fontSize="sm" color="grey">
                          Flex pay repayment details
                        </Text>
                      </Box>
                    </>
                  )}
                </Td>
                <Td style={{ verticalAlign: "top" }}>
                  {request.status.replace("_", " ")}
                  {request.amountCredit > 0 &&
                    [
                      StatusEnum.PastDue,
                      StatusEnum.PaymentSubmitted,
                      StatusEnum.InvoiceSent,
                    ].includes(request.status) && (
                      <Flex width="100%" justifyContent="left">
                        {" "}
                        {/* Use Flex to center the button */}
                        <Button
                          mt={"5px"}
                          size={"sm"}
                          colorScheme="blue"
                          onClick={() => {
                            setModalPayment(request);
                            setIsFlexPayRepayModalOpen(true);
                          }}
                        >
                          Repay Flex pay
                        </Button>
                      </Flex>
                    )}
                </Td>
                <Td style={{ verticalAlign: "top" }} pt="5px" maxW="200px">
                  {request.amountCredit !== request.amount &&
                  !(request as any).files?.find(
                    (f: any) => f.type === TypeEnum.ProofOfUserPayment
                  ) ? (
                    <FormControl>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange} // No need to pass directPaymentId here
                        accept="image/*, .pdf, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .jpg, .png, .jpeg"
                      />
                      <Button
                        onClick={() => triggerFileInput(request.id)} // Pass directPaymentId to triggerFileInput
                        isLoading={loadingStates[request.id]}
                      >
                        <Text fontSize="sm" color="grey">
                          Upload proof <br /> of payment
                        </Text>
                      </Button>
                    </FormControl>
                  ) : (
                    <>
                      {request.files?.map((file, index) => (
                        <Button
                          fontSize="sm"
                          color="grey"
                          key={index}
                          onClick={() => downloadFile(file.key)}
                        >
                          PROOF OF PAYMENT
                        </Button>
                      ))}
                    </>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
      {modalPayment && (
        <PaymentDetailsModal
          isOpen={isModalOpen}
          paymentRequest={modalPayment}
          currency={modalPayment.amountCurrency}
          supplier={suppliers.find((s) => s.id === modalPayment.supplierId)}
          onClose={() => toggleModal(null)}
        />
      )}
      {modalPayment && (
        <FlexPayPaymentDetailsModal
          isOpen={isFlexPayModalOpen}
          paymentRequest={modalPayment}
          currency={modalPayment.amountToRepayCurrency}
          onClose={() => toggleFlexPayModal(null)}
          country={country}
        />
      )}
      {modalPayment && (
        <MobilePayModal
          isOpen={isFlexPayRepayModalOpen}
          onClose={() => setIsFlexPayRepayModalOpen(false)}
          paymentRequest={modalPayment}
          currency={modalPayment.amountCreditCurrency}
        />
      )}
    </Box>
  );
}
